import {useState} from 'react'
import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, DialogContentText, Divider, Grid, Link, Typography} from "@mui/material"
import './LicenseSetting.scss'
import {TrackActionEvent} from '../../../../service/SegmentService'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import CheckIcon from '@mui/icons-material/Check'
import {useUser} from '@clerk/clerk-react'
import Spinner from '../../../spinner/Spinner'
import {PlansDialog} from './PlansDialog'
import {getPeriodByPriceId, getPlanByPriceId, getPlanPrice} from '../../../../utils/licenseUtils'
import {PRICES_INFO_MAP} from '../../../../constants/StripeConstants'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {PlansConfirmationDialog} from './PlansConfirmationDialog'
import {usePaymentContext} from '../../../../context/PaymentContext'
import {useStripeSubscriptionsContext} from '../../../../context/StripeSubscriptionsContext'

export const LicenseSettings = () => {
	const {user} = useUser()

	const {currentSubscription, cancelCurrentSubscription} = useStripeSubscriptionsContext()
	const {
		openChangePlanConfirmationDialog,
		openChangePlanDialog,
		setOpenChangePlanDialog,
		setOpenChangePlanConfirmationDialog
	} = usePaymentContext()

	const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = useState(false)

	if (!currentSubscription) return <Grid container className='licenseSettings'><Spinner/></Grid>

	const plan = getPlanByPriceId(currentSubscription.plan.id)
	const period = getPeriodByPriceId(currentSubscription.plan.id)
	const planPrice = plan && period ? getPlanPrice(plan, period, currentSubscription.quantity) : 0
	const benefits = plan ? PRICES_INFO_MAP[plan].benefits : []
	const isCurrentlySubscriptionCancelled = currentSubscription.cancellation_details?.reason === 'cancellation_requested'
	const licenseExpirationDate = formatDateStringToLocaleDate(currentSubscription.current_period_end * 1000)

	const onCurrentSubscriptionClick = () => {
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'changeSubtab',
			tab: 'currentSubscription'
		})
	}

	const onChangeCurrentPlanClick = () => {
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'changePlanModalOpen',
		})
		setOpenChangePlanDialog(true)
	}

	const onCancelSubscriptionClick = async () => {
		await cancelCurrentSubscription(currentSubscription.id)
		setShowCancelSubscriptionDialog(false)
	}

	return <Grid container className='licenseSettings'>
		<Grid item xs={2}>
			<Link className='licenseSubmenu active' onClick={onCurrentSubscriptionClick}>
				<AccountBalanceWalletIcon fontSize="small"/>
				Current Subscription
			</Link>
		</Grid>
		<Grid item xs={10} className='submenuContent'>
			<Box className='licenseTitleContainer'>
				<Box className='licenseTitleWrapper'>
					<Typography className='licenseName'>{plan} plan</Typography>
					{plan !== 'trial' && <Chip label={period} variant='outlined' className='periodChip'/>}
				</Box>
				<Button variant='outlined' className='changePlanButton' onClick={onChangeCurrentPlanClick}>Change plan</Button>
			</Box>

			<Typography className='pricePerSeat'>${planPrice} per seat</Typography>
			<Typography className='seatsCount'>{currentSubscription.quantity} seats</Typography>

			<Accordion className='benefitsAccordion'>
				<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
					<InfoIcon className='infoIcon'/> <Typography className='benefitsTitle'>Included in your plan</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container className='benefitContainer'>
						{benefits.map((benefit, index) => <Grid item xs={4} md={3} className='benefitItem' key={`benefit${index}`}>
							<Typography> <CheckIcon className='checkIcon'/>{benefit}</Typography>
						</Grid>)}
					</Grid>
				</AccordionDetails>
			</Accordion>
			{plan !== 'trial' && <>
				<Typography variant='h3' className='subscriptionDetails'>Subscription details</Typography>
				<Divider/>
				<Typography variant='h4' className='periodBilling'>Monthly billing</Typography>
				<Typography className='nextPeriod'>{isCurrentlySubscriptionCancelled ? `You have cancelled your subscription to Narus. Cancellation will be active on ${licenseExpirationDate} and users will not have access. ` : `Next charge on ${licenseExpirationDate}`}</Typography>
				<Divider/>
				<Typography className='newSeats'>The new seats added will be available immediately and will be billed retroactively in the next billing cycle for the remainder of the month.</Typography>
			</>}
			<PlansDialog open={openChangePlanDialog} onClose={() => setOpenChangePlanDialog(false)}/>
			<PlansConfirmationDialog open={openChangePlanConfirmationDialog} plan={plan} onClose={() => setOpenChangePlanConfirmationDialog(false)}/>
			<Box className='actionContainer'>
				{!isCurrentlySubscriptionCancelled && <Button variant='outlined' color='error' onClick={() => setShowCancelSubscriptionDialog(true)}>CANCEL SUBSCRIPTION</Button>}
				<ConfirmationDialog handleClose={() => setShowCancelSubscriptionDialog(false)} open={showCancelSubscriptionDialog}
				                    handleDelete={onCancelSubscriptionClick}
				                    currentEvent='cancelSubscription'>
					<DialogContentText>Narus users in your organization will still have access until the next payment cycle on {licenseExpirationDate}</DialogContentText>
				</ConfirmationDialog>
			</Box>
		</Grid>
	</Grid>
}